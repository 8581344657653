<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
    <b-card v-else>
      <template v-slot:header>
        <h5>Arsip FPP</h5>
      </template>
      <!-- Filter -->
      <div class="search-wrapper d-flex align-items-center justify-content-between">
        <div class="d-flex search-input-wrapper">
          <b-form-group class="form-inline form-group-wrapper mb-3">
            <b-form-select size="md" class="ml-0 mr-2" v-model="formFilter.selectFilter" @change="resetInputFilter"
              :plain="false">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pencarian berdasarkan</b-form-select-option>
              </template>

              <b-form-select-option v-for="option in optionsFilter" v-bind:key="option.value" v-bind:value="option.value">
                {{ option.label }}
              </b-form-select-option>
            </b-form-select>

            <template v-if="formFilter.selectFilter == 'tanggal'">
              <date-picker class="mr-2" v-model="formFilter.from" placeholder="Contoh: 25 Apr 2023"
                :config="configFilter"></date-picker>

              <span class="my-filter">-</span>

              <date-picker class="ml-2 mr-2" v-model="formFilter.until" placeholder="Contoh: 25 Apr 2023"
                :config="configFilter"></date-picker>

              <b-button class="mr-2 btn-labeled" variant="pgiBtn" @click="resultFilter">
                <span class="btn-label"> <i class="fa fa-search"></i> </span>Cari
              </b-button>
            </template>

            <!-- Filter Cabang -->
            <template v-if="formFilter.selectFilter == 'cabang'">
              <multiselect class="mr-2 my-multiselect" placeholder="Pilih Cabang" label="name" track-by="id"
                v-model="formFilter.selectCabang" :show-labels="false" :close-on-select="false" :clear-on-select="false"
                :preserve-search="true" :multiple="true" :options="optionsCabang" @select="onSelect($event)"
                @remove="onRemove($event)" @input="resultFilter">
                <template class="checkbox-label" slot="option" slot-scope="scope">
                  {{ scope.option.name }}
                  <input class="custom-check" type="checkbox" v-model="scope.option.checked" @focus.prevent />
                </template>
                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                    v-if="values.length" v-show="!isOpen">{{ values.length }} cabang ditandai</span></template>
              </multiselect>
            </template>

            <!-- Filter Kabupaten Kota -->
            <template v-if="formFilter.selectFilter == 'kabkot'">
              <multiselect class="mr-2 my-multiselect" placeholder="Pilih Kabupaten/Kota" label="text" track-by="id"
                v-model="formFilter.selectKabKot" :show-labels="false" :close-on-select="true" :clear-on-select="false"
                :multiple="false" :options="optionsKabKot" @input="resultFilter"></multiselect>
            </template>

            <!-- Filter Jenis Kategori -->
            <b-form-select v-if="formFilter.selectFilter == 'jenis-kategori'" size="md" class="mr-2"
              v-model="formFilter.selectJenisKategori" @change="resultFilter" :plain="false">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pilih Jenis Pengajuan</b-form-select-option>
              </template>

              <b-form-select-option v-for="option in optionsKategoriJenis" v-bind:key="option.id"
                v-bind:value="option.id">
                {{ option.name }}
              </b-form-select-option>
            </b-form-select>

            <!-- Filter Kategori -->
            <b-form-select v-if="formFilter.selectFilter == 'kategori'" size="md" class="mr-2"
              v-model="formFilter.selectKategori" @change="resultFilter" :plain="false">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pilih Kategori</b-form-select-option>
              </template>

              <b-form-select-option v-for="option in getKategori" v-bind:key="option.id" v-bind:value="option.id">
                {{ option.name }}
              </b-form-select-option>
            </b-form-select>

            <!-- Filter Sub Kategori -->
            <b-form-select v-if="formFilter.selectFilter == 'sub-kategori'" size="md" class="mr-2"
              v-model="formFilter.selectSubKategori" @change="resultFilter" :plain="false">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pilih Pengajuan</b-form-select-option>
              </template>

              <b-form-select-option v-for="option in optionsSubKategori" v-bind:key="option.id" v-bind:value="option.id">
                {{ option.name }}
              </b-form-select-option>
            </b-form-select>

            <!-- Filter Status -->
            <b-form-select v-if="formFilter.selectFilter == 'status'" size="md" class="mr-2"
              v-model="formFilter.selectStatus" @change="resultFilter" :plain="false">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pilih Status</b-form-select-option>
              </template>

              <b-form-select-option v-for="option in optionsStatus" v-bind:key="option.value" v-bind:value="option.value">
                {{ option.label }}
              </b-form-select-option>
            </b-form-select>

            <b-button variant="primary" class="btn-labeled" @click="onReset"><span class="btn-label"><i
                  class="fa fa-undo"></i></span>Atur
              Ulang Pencarian</b-button>
          </b-form-group>
        </div>
      </div>

      <!-- Table Pengajuan -->
      <b-table bordered hover show-empty :busy="isBusy" :items="daftarFPP" :fields="fields" :sort-by.sync="sortBy"
        class="mb-3 my-custom-table table-daftar-fpp">
        <template #empty="scope"> Data tidak ditemukan </template>

        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner small class="align-middle" variant="secondary"></b-spinner>
            <span class="font-weight-bold text-secondary">
              Harap tunggu...</span>
          </div>
        </template>

        <template v-slot:cell(tanggal)="row">
          {{ row.item.tanggal | moment("D MMM Y, HH:mm") }}
        </template>

        <template v-slot:cell(jenis_pengajuan)="row">
          {{ row.item.jenis_pengajuan }}
        </template>

        <template v-slot:cell(status)="row">
          <div v-if="row.item.status == 0">
            <span class="badge badge-warning">Pengajuan Ditinjau Ulang</span>
          </div>

          <div v-else-if="row.item.status == 1">
            <span class="badge badge-primary">Pengajuan Disetujui (1/2)</span>
          </div>

          <div v-else-if="row.item.status == 2">
            <span class="badge badge-primary text-white">Pengajuan Disetujui (2/2)</span>
          </div>

          <div v-else-if="row.item.status == 3">
            <span class="badge badge-info text-white">Pengajuan Diproses</span>
          </div>

          <div v-else-if="row.item.status == 4">
            <span class="badge badge-success text-white">Pengajuan Selesai</span>
          </div>

          <div v-else-if="row.item.status == 8">
            <span class="badge badge-secondary">Pengajuan Dibatalkan</span>
          </div>

          <div v-else-if="row.item.status == 10">
            <span class="badge badge-dark">Pengajuan Ditolak</span>
          </div>

          <div v-else-if="row.item.status == 11">
            <span class="badge badge-danger">Pekerjaan Dijadwalkan Ulang</span>
          </div>

          <div v-else-if="row.item.status == 12">
            <span class="badge badge-success">Petugas Hadir ({{ row.item.total_maintenance }}/{{
              row.item.count_attendance_maintenance
            }})
            </span>
          </div>

          <div v-else-if="row.item.status == 13">
            <span class="badge badge-success">Menunggu Penilaian </span>
          </div>
        </template>

        <template v-slot:cell(aksi)="row">
          <b-button size="sm" class="ml-1 mr-1" variant="pgiBtn" title="Detail" name="btnDetailPersetujuan"
            @click="showModalDetail(`${row.item.id}`)">
            <i class="fa fa-info mr-1 ml-1" aria-hidden="true"></i>
          </b-button>
        </template>
      </b-table>

      <!-- Pagination -->
      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <b-input-group prepend="Menampilkan" class="mt-3 font-weight-bold">
          <b-input-group-append>
            <b-input-group-text class="bg-white">
              <strong class="mr-1">{{ totalViewText }}</strong>
              <span class="mr-1">dari</span>
              <strong>{{ rows }}</strong>
            </b-input-group-text>
            <b-input-group-text>entri</b-input-group-text>
          </b-input-group-append>
        </b-input-group>

        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>

    <!-- Modal Detail -->
    <b-modal id="m-detail" size="xl" title="Detail Pekerjaan" ref="my-modal" @hidden="hideModal" no-close-on-backdrop
      no-close-on-esc hide-footer>
      <LoadingWidget v-if="initStatusDetail != 1" :status="initStatusDetail" @init="showModalDetail" />
      <template v-else>
        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">No. Pengajuan</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.noPengajuan }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Cabang</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.cabang }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Divisi</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.divisi }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Kategori</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.namaKategori }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Pengajuan</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.pengajuan }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Jumlah</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.kuantitas }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Catatan</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.catatan }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Estimasi Pengerjaan</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.estimasi }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Sisa Waktu Pengerjaan</span>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <div v-if="form.status != 4 && form.status != 8 && form.status != 10">
              <template v-if="timeDifference < 0">
                <span v-show="!loadCountDown" class="text-danger">
                  {{ form.timerInProgress }}</span>
              </template>

              <template v-else-if="timeDifference > 0">
                <span v-show="!loadCountDown">{{ form.timerInProgress }}</span>
              </template>

              <span v-show="loadCountDown" class="spinner-border spinner-border-sm"></span>
            </div>

            <div v-else>
              <template v-if="calc > 0">
                <span class="text-danger">{{ form.timerFinish }}</span>
              </template>

              <template v-else-if="calc < 0">
                <b-badge variant="success">estimasi waktu pengerjaan terpenuhi
                  <i class="fa fa-check"></i></b-badge>
              </template>
            </div>
          </b-col>
        </b-row>

        <template v-if="form.status == 10">
          <b-row>
            <b-col cols="12" sm="12" md="6" lg="4" xl="3">
              <span class="font-weight-bold">Keterangan</span>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="4" xl="3">
              <span>{{ form.catatan_tolak }}</span>
            </b-col>
          </b-row>
        </template>

        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12">
            <span class="font-weight-bold">File Pendukung</span>
          </b-col>
          <b-col cols="12">
            <b-container fluid class="p-0 bg-white">
              <ul class="image-gallery mt-2">
                <li v-bind:key="index" v-for="(file, index) in form.file">
                  <template v-if="file.flag == 'foto' || file.flag == 'video'">
                    <div v-if="file.path.split('.').pop() == 'pdf'">
                      <iframe class="my-iframe" allowfullscreen :src="file.path"></iframe>
                    </div>

                    <div v-else-if="file.path.split('.').pop() == 'mp4'">
                      <iframe class="my-iframe" allowfullscreen :src="file.path"></iframe>
                    </div>

                    <div v-else>
                      <expandable-image :src="file.path" />
                    </div>
                  </template>
                </li>
              </ul>
            </b-container>
          </b-col>

          <b-col cols="12" v-if="lengthBuktiPekerjaan > 0">
            <span class="font-weight-bold">Bukti Pekerjaan</span>
          </b-col>

          <b-col cols="12">
            <b-container fluid class="p-0 bg-white">
              <ul class="image-gallery mt-2">
                <li v-bind:key="index" v-for="(file, index) in buktiPekerjaan">
                  <div v-if="file.path.split('.').pop() === 'pdf'">
                    <iframe class="my-iframe" allowfullscreen :src="file.path"></iframe>
                  </div>

                  <div v-else-if="file.path.split('.').pop() === 'mp4'">
                    <iframe class="my-iframe" allowfullscreen :src="file.path"></iframe>
                  </div>

                  <div v-else>
                    <expandable-image :src="file.path" />
                  </div>
                </li>
              </ul>
            </b-container>
          </b-col>
        </b-row>

        <!-- Table Material Maintenance -->
        <template v-if="lengthMaterial > 0">
          <b-row class="mb-2">
            <b-col cols="12" sm="12" md="12" lg="12" xl="7">
              <span class="font-weight-bold">Daftar Material/Bahan</span>
              <b-table bordered hover show-empty small class="mt-2 mb-3 my-custom-table table-material"
                :fields="fieldsMaterial" :items="form.barang">
                <template #empty="scope"> Data tidak ditemukan </template>

                <template v-slot:cell(no)="row">
                  {{ row.item.no }}
                </template>
              </b-table>
            </b-col>
          </b-row>
        </template>

        <!-- Table User Maintenance -->
        <template v-if="lengthMemoMaintenance > 0">
          <b-row class="mb-2">
            <b-col cols="12" sm="12" md="12" lg="12" xl="7">
              <span class="font-weight-bold">Petugas Pemeliharaan</span>
              <b-table bordered hover show-empty small class="mt-2 mb-3 my-custom-table table-user-maintenance"
                :fields="fieldsMaintenance" :items="form.memo_maintenance">
                <template #empty="scope"> Data tidak ditemukan </template>

                <template v-slot:cell(no)="row">
                  {{ row.item.no }}
                </template>

                <template v-slot:cell(tanggal)="row">
                  {{ row.item.tanggal | moment("D MMM Y") }}
                </template>

                <template v-slot:cell(foto)="row">
                  <template v-if="row.item.foto === null">
                    <div>
                      <expandable-image :src="$defaultUser" />
                    </div>
                  </template>

                  <template v-else>
                    <div>
                      <!-- http://128.199.127.61/file-portal-dev -->
                      <!-- ${proses.env.VUE_APP_ASSET} -->
                      <expandable-image :src="row.item.foto" />
                    </div>
                  </template>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </template>

        <div class="mt-4 text-right">
          <b-button variant="dark" class="btn-labeled mr-1 ml-1" @click="hideModal"><span class="btn-label"><i
                class="fa fa-times"></i></span>Tutup</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required, maxLength } from "/node_modules/vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data: () => {
    return {
      initStatus: 0,
      initStatusDetail: 0,
      initStatusConfirm: 0,
      sortBy: "status",
      fields: [
        {
          key: "no_pengajuan",
          label: "No. Pengajuan",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal",
          thClass: "nameOfTheClassThTanggal",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "cabang",
          label: "Cabang",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "kabkot",
          label: "Kabupaten/Kota",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "jenis_pengajuan",
          label: "Pengajuan",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "aksi",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      fieldsMaintenance: [
        {
          key: "no",
          label: "No",
          thClass: "nameOfTheClassThNo",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "tanggal",
          label: "Tanggal Bertugas",
          thClass: "nameOfTheClassThTanggal",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "nama",
          label: "Nama",
          thClass: "nameOfTheClassTh",
        },
        {
          key: "no_telp",
          label: "No. HP",
          thClass: "nameOfTheClassThHP",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "foto",
          label: "Foto",
          thClass: "nameOfTheClassThFoto",
          tdClass: "nameOfTheClassTd",
        },
      ],
      fieldsMaterial: [
        {
          key: "no",
          label: "No",
          thClass: "nameOfTheClassThNo",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "material",
          label: "Material/Bahan",
          thClass: "nameOfTheClassTh",
        },
        {
          key: "quantity",
          label: "Jumlah",
          thClass: "nameOfTheClassThQuantity",
          tdClass: "nameOfTheClassTd",
        },
      ],
      form: {
        id: null,
        id_user: null,
        id_kategori_jenis_fpp: null,
        noPengajuan: null,
        cabang: null,
        divisi: "",
        namaKategori: "",
        pengajuan: "",
        kuantitas: null,
        catatan: "",
        catatan_tolak: "",
        status: null,
        file: [],
        memo_rating: [],
        memo_maintenance: [],
        barang: [],
        estimasi: "",
        timerInProgress: null,
        timerFinish: null,
      },
      formFilter: {
        from: null,
        until: null,
        selectFilter: null,
        selectJenisKategori: null,
        selectKategori: null,
        selectSubKategori: null,
        selectStatus: null,
        selectKabKot: null,
        selectCabang: [],
      },
      optionsFilter: [
        {
          value: "tanggal",
          label: "Tanggal",
        },
        {
          value: "cabang",
          label: "Cabang",
        },
        {
          value: "kabkot",
          label: "Kabupaten/Kota",
        },
        {
          value: "jenis-kategori",
          label: "Jenis Pengajuan",
        },
        {
          value: "kategori",
          label: "Kategori Pengajuan",
        },
        {
          value: "sub-kategori",
          label: "Pengajuan",
        },
        {
          value: "status",
          label: "Status",
        },
      ],
      optionsStatus: [
        {
          value: 3,
          label: "Diproses",
        },
        {
          value: 4,
          label: "Diselesaikan",
        },
        {
          value: 11,
          label: "Dijadwalkan Ulang",
        },
      ],
      daftarFPP: [],
      detailPengajuan: [],
      optionsCabang: [],
      optionsKabKot: [],
      optionsKategoriJenis: [],
      optionsSubKategori: [],
      optionsMaintenance: [],
      historyMemo: [],
      getCabang: [],
      optionsChecked: [],
      buktiPekerjaan: [],
      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      url: "",
      param: "",
      lengthBuktiPekerjaan: "",
      lengthMaterial: "",
      lengthMemoMaintenance: "",
      isBusy: true,
      loadCountDown: false,
      countDownToTime: null,
      timeDifference: null,
      calc: null,
    };
  },
  validations: {
    formConfirm: {
      kode: { required, maxLength: maxLength(4) },
    },
  },

  //----------------- Fungsi Inisialisasi -----------------//
  methods: {
    init: function () {
      this.initStatus = 0;
      let getAllData = "";

      // Get Main
      getAllData = this.$axios
        // list-memo-maintenance-archive-archive
        .get("api/internal-memo/memo/list-memo-maintenance-archive")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;
            let jenisPengajuan = "";

            for (let data of datas.data) {
              if (data.flag == 4) {
                if (data.kategori_sub == null) {
                  data.kategori_sub = { name: "-" };
                }

                jenisPengajuan =
                  data.kategori_jenis.name + " " + data.kategori_sub.name;

                this.daftarFPP.push({
                  id: data.id,
                  cabang: data.cabang.name,
                  kabkot: data.cabang.kabupaten_kota.name,
                  jenis_pengajuan: jenisPengajuan,
                  catatan: data.catatan,
                  tanggal: data.created_at,
                  status: data.flag,
                  no_pengajuan: data.im_number,
                  link: data.maintenance_user[0].link,
                  flag: data.maintenance_user[0].flag,
                  count_attendance_maintenance:
                    data.memo_maintenance_count_count,
                  total_maintenance: data.total_user_maintenance_count,
                });
              }
            }

            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = this.daftarFPP.length == 0 ? "0" : datas.from;
            this.limit = this.daftarFPP.length;

            this.updateTotalItem(datas.total);
            // this.updateTotalItem(this.daftarFPP.length);
          }
        })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
        });

      // Get Cabang
      getAllData = this.$axios.get("api/admin/cabang/all").then((response) => {
        if (response.status == 200) {
          let datas = response.data.data;

          for (let cabang = 0; cabang < datas.length; cabang++) {
            this.optionsCabang.push({
              id: datas[cabang].id,
              name: datas[cabang].name,
              checked: false,
            });
          }
        }
      });

      // Get Kategori Jenis
      getAllData = this.$axios
        .get("api/internal-memo/kategori-jenis/all")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (let jenis = 0; jenis < datas.length; jenis++) {
              this.optionsKategoriJenis.push({
                id: datas[jenis].id,
                name: datas[jenis].name,
              });
            }
          }
        });

      // Get Sub Kategori
      getAllData = this.$axios
        .get("api/internal-memo/kategori-sub/all")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (
              let subKategori = 0;
              subKategori < datas.length;
              subKategori++
            ) {
              this.optionsSubKategori.push({
                id: datas[subKategori].id,
                name: datas[subKategori].name,
              });
            }
          }
        });

      // Get Kabupaten/Kota
      getAllData = this.$axios
        .get("api/admin/wilayah/kabupaten")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (var kabkot of datas) {
              this.optionsKabKot.push({
                id: kabkot.id,
                text: kabkot.name,
              });
            }
          }
        });

      Promise.all([getAllData])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
          this.toggleBusy();
        });
    },

    // Result Filter
    resultFilter: function (value) {
      this.toggleBusy();

      if (this.formFilter.selectJenisKategori) {
        this.url =
          "api/internal-memo/memo/list-memo-maintenance-archive?id_kategori_jenis_fpp=" +
          this.formFilter.selectJenisKategori;
      } else if (this.formFilter.selectKategori) {
        this.url =
          "api/internal-memo/memo/list-memo-maintenance-archive?id_kategori_fpp=" +
          this.formFilter.selectKategori;
      } else if (this.formFilter.selectSubKategori) {
        this.url =
          "api/internal-memo/memo/list-memo-maintenance-archive?id_kategori_sub_fpp=" +
          this.formFilter.selectSubKategori;
      } else if (this.formFilter.from && this.formFilter.until) {
        this.url =
          "api/internal-memo/memo/list-memo-maintenance-archive?startDate=" +
          this.formFilter.from +
          "&endDate=" +
          this.formFilter.until;
      } else if (this.formFilter.selectStatus) {
        this.url =
          "api/internal-memo/memo/list-memo-maintenance-archive?flag=" +
          this.formFilter.selectStatus;
      } else if (this.formFilter.selectKabKot) {
        this.url =
          "api/internal-memo/memo/list-memo-maintenance-archive?kabupaten_kota_id=" +
          this.formFilter.selectKabKot;
      } else if (this.formFilter.selectCabang) {
        if (value) {
          let arr = [];

          for (let data of value) {
            arr.push(data.id);
          }

          this.param = {
            params: { id_cabang_multiple: arr },
          };
        }

        if (this.query) {
          this.url =
            "api/internal-memo/memo/list-memo-maintenance-archive" + this.query;
        } else {
          this.url = "api/internal-memo/memo/list-memo-maintenance-archive";
        }
      } else {
        this.url = "api/internal-memo/memo/list-memo-maintenance-archive" + this.query;
      }

      this.$axios
        .get(this.url, value ? this.param : "")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;
            let jenisPengajuan = "";

            this.toggleBusy();
            this.daftarFPP = [];

            for (let data of datas.data) {
              if (data.flag == 4) {
                if (data.kategori_sub == null) {
                  data.kategori_sub = { name: "-" };
                }

                jenisPengajuan =
                  data.kategori_jenis.name + " " + data.kategori_sub.name;

                this.daftarFPP.push({
                  id: data.id,
                  cabang: data.cabang.name,
                  kabkot: data.cabang.kabupaten_kota.name,
                  jenis_pengajuan: jenisPengajuan,
                  catatan: data.catatan,
                  tanggal: data.created_at,
                  status: data.flag,
                  no_pengajuan: data.im_number,
                  link: data.maintenance_user[0].link,
                  flag: data.maintenance_user[0].flag,
                  count_attendance_maintenance:
                    data.memo_maintenance_count_count,
                  total_maintenance: data.total_user_maintenance_count,
                });
              }
            }

            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = this.daftarFPP.length == 0 ? "0" : datas.from;
            this.limit = this.daftarFPP.length;

            this.updateTotalItem(datas.total);
            // this.updateTotalItem(this.daftarFPP.length);
          }
        })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
          this.loading = false;
        });
    },

    //----------------- Pagination, Sorting, dan Filtering Function -----------------//
    onPageChange: function () {
      this.page = this.currentPage;
      this.query = "?page=" + this.page;
      this.resultFilter();
      // if (this.loading == false) {
      //   this.page = this.currentPage;
      //   this.query = "?page=" + this.page;
      //   this.resultFilter();
      // }
    },

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
      } else {
        this.isBusy = true;
      }
    },

    updateTotalItem: function (total) {
      this.perPage = this.size;
      this.rows = total;

      const resultFrom = this.from == null ? 0 : this.from;
      const resultLimit = this.limit == null ? 0 : this.limit;

      this.totalViewText = resultFrom + " - " + resultLimit;
    },

    NumbersOnly: (event) => {
      event = event ? event : window.event;
      var charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    customLabel(option) {
      return `${option.name} - ${option.id}`;
    },

    onSelect(option) {
      let index = this.optionsCabang.findIndex((item) => item.id == option.id);
      this.optionsCabang[index].checked = true;

      this.optionsChecked.push({
        id: option.id,
        checked: option.checked,
      });
    },

    onRemove(option) {
      let index = this.optionsCabang.findIndex((item) => item.id == option.id);
      this.optionsCabang[index].checked = false;
    },

    //----------------- Hide Modal Function -----------------//
    hideModal: function () {
      this.$refs["my-modal"].hide();

      this.form = {
        id: null,
        id_user: null,
        noPengajuan: null,
        cabang: null,
        divisi: "",
        namaKategori: "",
        pengajuan: "",
        kuantitas: null,
        catatan: "",
        estimasi: "",
        timerInProgress: null,
        timerFinish: null,
        status: null,
        file: [],
        memo_rating: [],
        memo_maintenance: [],
        barang: [],
      };

      this.formConfirm = {
        id_memo: null,
        link: null,
        kode: null,
        flag: null,
      };

      this.countDownToTime = null;
      this.timeDifference = null;
      this.calc = null;
      this.historyMemo = [];
      this.buktiPekerjaan = [];

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onReset: function () {
      this.formFilter = {
        from: null,
        until: null,
        selectFilter: null,
        selectJenisKategori: null,
        selectKategori: null,
        selectSubKategori: null,
        selectKabKot: null,
        selectStatus: null,
        selectCabang: [],
      };

      for (var data of this.optionsChecked) {
        let index = this.optionsCabang.findIndex((item) => item.id == data.id);
        this.optionsCabang[index].checked = false;
      }

      this.resultFilter();
    },

    resetInputFilter: function () {
      this.formFilter.from = null;
      this.formFilter.until = null;
      this.formFilter.selectJenisKategori = null;
      this.formFilter.selectKategori = null;
      this.formFilter.selectSubKategori = null;
      this.formFilter.selectKabKot = null;
      this.formFilter.selectCabang = [];

      for (var data of this.optionsChecked) {
        let index = this.optionsCabang.findIndex((item) => item.id == data.id);
        this.optionsCabang[index].checked = false;
      }

      this.resultFilter();
    },

    //----------------- Detail Function -----------------//
    showModalDetail: function (id) {
      this.initStatusDetail = 0;

      let getDetail = this.$axios
        .get("api/internal-memo/memo/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.loadCountDown = true;
            setInterval(() => {
              this.loadCountDown = false;
            }, 3000);

            let datas = response.data.data;
            console.log(datas);

            let proses = "";
            let number = 1;
            let numberMaterial = 1;

            this.form.id = datas.id;
            this.form.id_user = datas.created_by.id;
            this.form.id_kategori_jenis_fpp = datas.id_kategori_jenis_fpp;
            this.form.noPengajuan = datas.im_number;
            this.form.cabang = datas.cabang.name ? datas.cabang.name : "-";
            this.form.divisi = datas.devisi.nm_Divisi;
            this.form.pengajuan =
              datas.kategori_sub == null ? "-" : datas.kategori_sub.name;
            this.form.kuantitas = datas.qty ? datas.qty : "-";
            this.form.prosesTerakhir = datas.list_history_memo;
            this.form.catatan = datas.catatan;
            this.form.catatan_tolak = datas.catatan_tolak;
            this.form.status = datas.flag;
            this.form.file = datas.memo_file;

            for (var dataFile of this.form.file) {
              if (
                dataFile.flag === "foto_pic_ku" ||
                dataFile.flag === "video_pic_ku"
              ) {
                this.buktiPekerjaan.push({
                  flag: dataFile.flag,
                  path: dataFile.path,
                });
              }
            }

            if (datas.barang) {

              if (datas.barang.length >= 1) {
                for (let x of datas.barang) {
                  if (x) {
                    this.form.barang.push({
                      no: numberMaterial++,
                      material: x.tipe,
                      quantity: x.quantity,
                    });
                  }
                }
              }
            }

            for (var dataMemoMaintenance of datas.memo_maintenance) {
              for (var dataUserMaintenance of dataMemoMaintenance.user_maintenance) {
                this.form.memo_maintenance.push({
                  no: number++,
                  id: dataUserMaintenance.id,
                  tanggal: dataMemoMaintenance.date,
                  nama: dataUserMaintenance.nama,
                  no_telp: dataUserMaintenance.no_telp,
                  foto: dataUserMaintenance.foto,
                });
              }
            }

            for (var dataHistory of datas.list_history_memo) {
              this.historyMemo.push({
                tanggal: dataHistory.created_at,
                status: dataHistory.status,
                keterangan: dataHistory.keterangan,
                waktu_proses: proses,
              });
            }

            let cekTimeByFlag = this.historyMemo.find(
              (flag) =>
                flag.status == 4 || flag.status == 8 || flag.status == 10
            );

            this.form.namaKategori = datas.kategori_jenis.name;
            this.form.estimasi = datas.kategori_sub.sla + " hari";

            var createMemo = new Date(datas.created_at);
            var millisecondsInOneSecond = 1000;
            var millisecondsInOneMinute = millisecondsInOneSecond * 60;
            var millisecondsInOneHour = millisecondsInOneMinute * 60;
            var millisecondsInOneDay = millisecondsInOneHour * 24;

            var differenceInDays = "";
            var remainderDifferenceInHours = "";
            var remainderDifferenceInMinutes = "";
            var remainderDifferenceInSeconds = "";
            var remainingDays = "";
            var remainingHours = "";
            var remainingMinutes = "";
            var remainingSeconds = "";
            var countDate = createMemo.setDate(
              createMemo.getDate() + datas.kategori_sub.sla
            );
            var resultTime = new Date(countDate).getTime();

            this.countDownToTime = resultTime;

            if (datas.flag != 4 && datas.flag != 8 && datas.flag != 10) {
              var countDown = setInterval(() => {
                var timeNow = new Date().getTime();
                var resultTimeOut = this.countDownToTime - timeNow;

                differenceInDays = resultTimeOut / millisecondsInOneDay;
                remainderDifferenceInHours =
                  (resultTimeOut % millisecondsInOneDay) /
                  millisecondsInOneHour;
                remainderDifferenceInMinutes =
                  (resultTimeOut % millisecondsInOneHour) /
                  millisecondsInOneMinute;
                remainderDifferenceInSeconds =
                  (resultTimeOut % millisecondsInOneMinute) /
                  millisecondsInOneSecond;
                remainingDays = Math.floor(differenceInDays);
                remainingHours = Math.floor(remainderDifferenceInHours);
                remainingMinutes = Math.floor(remainderDifferenceInMinutes);
                remainingSeconds = Math.floor(remainderDifferenceInSeconds);

                if (remainingDays == 0) {
                  this.form.timerInProgress =
                    remainingHours +
                    ":" +
                    remainingMinutes +
                    ":" +
                    remainingSeconds;
                } else {
                  this.form.timerInProgress =
                    remainingDays +
                    " hari, " +
                    remainingHours +
                    ":" +
                    remainingMinutes +
                    ":" +
                    remainingSeconds;
                }

                if (resultTimeOut < 0) {
                  var timeDifference = timeNow - this.countDownToTime;

                  differenceInDays = timeDifference / millisecondsInOneDay;
                  remainderDifferenceInHours =
                    (timeDifference % millisecondsInOneDay) /
                    millisecondsInOneHour;
                  remainderDifferenceInMinutes =
                    (timeDifference % millisecondsInOneHour) /
                    millisecondsInOneMinute;
                  remainderDifferenceInSeconds =
                    (timeDifference % millisecondsInOneMinute) /
                    millisecondsInOneSecond;
                  remainingDays = Math.floor(differenceInDays);
                  remainingHours = Math.floor(remainderDifferenceInHours);
                  remainingMinutes = Math.floor(remainderDifferenceInMinutes);
                  remainingSeconds = Math.floor(remainderDifferenceInSeconds);

                  if (remainingDays == 0) {
                    this.form.timerInProgress =
                      "Lewat " +
                      remainingHours +
                      ":" +
                      remainingMinutes +
                      ":" +
                      remainingSeconds;
                  } else {
                    this.form.timerInProgress =
                      "Lewat " +
                      remainingDays +
                      " hari, " +
                      remainingHours +
                      ":" +
                      remainingMinutes +
                      ":" +
                      remainingSeconds;
                  }
                }
                this.timeDifference = resultTimeOut;
              }, 1000);
            } else {
              clearInterval(countDown);

              var getTimeByFlag = new Date(cekTimeByFlag.tanggal).getTime();
              var calc = getTimeByFlag - resultTime;

              differenceInDays = calc / millisecondsInOneDay;
              remainderDifferenceInHours =
                (calc % millisecondsInOneDay) / millisecondsInOneHour;
              remainderDifferenceInMinutes =
                (calc % millisecondsInOneHour) / millisecondsInOneMinute;
              remainderDifferenceInSeconds =
                (calc % millisecondsInOneMinute) / millisecondsInOneSecond;
              remainingDays = Math.floor(differenceInDays);
              remainingHours = Math.floor(remainderDifferenceInHours);
              remainingMinutes = Math.floor(remainderDifferenceInMinutes);
              remainingSeconds = Math.floor(remainderDifferenceInSeconds);

              this.calc = calc;

              if (remainingDays == 0) {
                this.form.timerFinish =
                  "Lewat " +
                  remainingHours +
                  ":" +
                  remainingMinutes +
                  ":" +
                  remainingSeconds;
              } else {
                this.form.timerFinish =
                  "Lewat " +
                  remainingDays +
                  " hari, " +
                  remainingHours +
                  ":" +
                  remainingMinutes +
                  ":" +
                  remainingSeconds;
              }
            }

            this.lengthMaterial = datas.barang.length;
            this.lengthBuktiPekerjaan = this.buktiPekerjaan.length;
            this.lengthMemoMaintenance = this.form.memo_maintenance.length;
            this.lengthHistory = this.historyMemo.length;
          }
        });

      Promise.all([getDetail])
        .then(() => {
          this.initStatusDetail = 1;
        })
        .catch((error) => {
          console.log(error);
          this.initStatusDetail = -1;
        });

      this.$bvModal.show("m-detail");
    },

    showModalConfirm: function (id, link, flag) {
      if (id && link && flag) {
        this.formConfirm.id_memo = id;
        this.formConfirm.link = link;
        this.formConfirm.flag = flag;

        if (flag == 0) {
          this.formConfirm.title = "Konfirmasi Kehadiran";
        } else if (flag == 1) {
          this.formConfirm.title = "Konfirmasi Selesai";
        } else if (flag == 3) {
          this.formConfirm.title = "Menunggu Konfirmasi";
        } else if (flag == 4) {
          this.formConfirm.title = "Pekerjaan Selesai";
        }
      }
      this.$bvModal.show("m-confirm");
    },

    onConfirm: function () {
      this.$v.formConfirm.$touch();

      if (this.$v.formConfirm.$anyError) {
        return;
      } else {
        this.showSweetAlertConfirm();
        this.$bvModal.hide("m-confirm");
      }
    },

    showSweetAlertConfirm: function () {
      var formConfirm = {
        link: this.formConfirm.link,
        kode: this.formConfirm.kode,
      };

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.$swal({
        html: "Mohon konfirmasi kehadiran",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Menunggu",
            html: "Kehadiran Anda lagi diproses nih...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post("/api/attendanceMaintenance/" + formConfirm.link, formConfirm)
            .then((response) => {
              if (response.status == 200) {
                Toast.fire({
                  title: "Sukses!",
                  iconHtml: `
                  <div class="m-2">
                    <i class="fa fa-check"></i>
                  </div>
                `,
                  iconColor: '#a5dc86',
                  text: "Konfirmasi kehadiran berhasil.",
                });

                this.onReset();
                this.resultFilter();
              }
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                title: "Gagal!",
                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                iconColor: '#dc3545',
                html: "Konfirmasi kehadiran gagal!",
              });
            });
        }
      });
    },

    onConfirmFinish: function () {
      this.$bvModal.hide("m-confirm");

      let param = {
        id_internal_memo: this.formConfirm.id_memo,
      };

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.$swal({
        html: "Mohon konfirmasi pekerjaan telah diselesaikan",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Menunggu",
            html: "Permintaan Anda sedang diproses nih...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post("/api/internal-memo/memo/konfirmasi-selesai", param)
            .then((response) => {
              if (response.status == 200) {
                Toast.fire({
                  title: "Sukses!",
                  iconHtml: `
                  <div class="m-2">
                    <i class="fa fa-check"></i>
                  </div>
                `,
                  iconColor: '#a5dc86',
                  text: "Konfirmasi pekerjaan selesai berhasil.",
                });
                this.onReset();
                this.resultFilter();
              }
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                title: "Gagal!",
                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                iconColor: '#dc3545',
                html: "Konfirmasi pekerjaan selesai gagal!",
              });
            });
        }
      });
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.b-table-empty-row {
  text-align: center;
}

.table th,
.table td {
  vertical-align: middle;
}

.nameOfTheClassTh {
  text-align: center;
}

.nameOfTheClassThNo {
  width: 50px;
  text-align: center;
}

.nameOfTheClassThHP {
  width: 15%;
  text-align: center;
}

.nameOfTheClassThQuantity {
  width: 12%;
  text-align: center;
}

.nameOfTheClassThFoto {
  width: 12%;
  text-align: center;
}

.nameOfTheClassThAction {
  width: 10%;
  text-align: center;
}

.nameOfTheClassThTanggal {
  text-align: center;
  width: 18%;
}

.nameOfTheClassThSelect {
  width: 5%;
  text-align: center;
}

.nameOfTheClassTd {
  text-align: center;
}

.image-gallery {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 10px;
  background: #e4e5e630;
  padding: 10px;
  border-radius: 5px;
  overflow-x: scroll;
}

.image-gallery>li {
  flex-basis: 250px;
  /* width: 350px; */
  list-style: none;
}

.image-gallery li .expandable-image img {
  object-fit: cover;
  width: 250px;
  height: 250px;
  vertical-align: middle;
  border-radius: 5px;
}

div .expandable-image img {
  height: 65px;
  object-fit: cover;
}

.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem 0 0 0.25rem;
}

.btn-labeled {
  padding-top: unset;
  padding-bottom: unset;
}

.multiselect {
  width: auto;
  display: inline-block;
}

.my-multiselect {
  width: auto;
  display: inline-block;
  vertical-align: middle;
}

.checkbox-label {
  display: block;
}

.custom-check {
  position: absolute;
  right: 1vw;
}

.my-iframe {
  width: 250px;
  height: 250px;
}

@media only screen and (max-width: 480px) {
  .multiselect {
    width: auto;
    display: block;
  }

  .my-filter {
    display: none;
  }

  .table-bordered {
    border: unset;
  }
}

// Mobile
@media only screen and (max-width: 1024px) {
  .pagination-wrapper .input-group-text {
    display: block;
  }

  .image-gallery>li {
    flex-basis: 100px;
    list-style: none;
  }

  .image-gallery li .expandable-image img {
    object-fit: cover;
    width: 100px;
    height: 100px;
    vertical-align: middle;
    border-radius: 5px;
  }

  div .expandable-image img {
    width: 150px;
    height: 200px;
    object-fit: cover;
  }

  .my-iframe {
    width: 100px;
    height: 100px;
  }

  .my-custom-table tbody th,
  .my-custom-table tbody td {
    position: relative;
  }

  .my-custom-table th,
  .my-custom-table td {
    padding: 0.6rem;
    vertical-align: middle;
  }

  /* Force table to not be like tables anymore */
  .my-custom-table thead,
  .my-custom-table tbody,
  .my-custom-table th,
  .my-custom-table td,
  .my-custom-table tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .my-custom-table thead tr,
  .my-custom-table tfoot tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .my-custom-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    text-align: left;
  }

  .my-custom-table td:before {
    position: absolute;
    top: 50%;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    left: 25%;
    margin: 0;
    margin: 0;
    transform: translate(-50%, -50%);
  }

  .my-custom-table td:nth-child(1) {
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    text-align: left;
    background-color: #304f7b;
    color: white;
  }

  .table-material td:nth-of-type(1):before {
    content: "No.";
  }

  .table-material td:nth-of-type(2):before {
    content: "Material/Bahan";
  }

  .table-material td:nth-of-type(3):before {
    content: "Jumlah";
  }

  .table-user-maintenance td:nth-of-type(1):before {
    content: "No.";
  }

  .table-user-maintenance td:nth-of-type(2):before {
    content: "Tanggal Bertugas";
  }

  .table-user-maintenance td:nth-of-type(3):before {
    content: "Nama";
  }

  .table-user-maintenance td:nth-of-type(4):before {
    content: "No. HP";
  }

  .table-user-maintenance td:nth-of-type(5):before {
    content: "Kode";
  }

  .table-user-maintenance td:nth-of-type(6):before {
    content: "Foto";
  }

  .table-history td:nth-of-type(1):before {
    content: "Nama";
  }

  .table-history td:nth-of-type(2):before {
    content: "Tanggal";
  }

  .table-history td:nth-of-type(3):before {
    content: "Status";
  }

  .table-history td:nth-of-type(4):before {
    content: "Waktu Proses";
  }

  .table-history td:nth-of-type(5):before {
    content: "Keterangan";
  }

  .table-daftar-fpp td:nth-of-type(1):before {
    content: "No. Pengajuan";
  }

  .table-daftar-fpp td:nth-of-type(2):before {
    content: "Tanggal";
  }

  .table-daftar-fpp td:nth-of-type(3):before {
    content: "Cabang";
  }

  .table-daftar-fpp td:nth-of-type(4):before {
    content: "Kabupaten/Kota";
  }

  .table-daftar-fpp td:nth-of-type(5):before {
    content: "Pengajuan";
  }

  .table-daftar-fpp td:nth-of-type(6):before {
    content: "Status";
  }

  .table-daftar-fpp td:nth-of-type(7):before {
    content: "Aksi";
  }

  .swal2-confirm {
    margin: 0 10px;
  }
}
</style>
